import React, {useState, useEffect} from "react";
import {authenticationService} from "../Scripts/authentication.service";
import Title from "../Comps/Title";
import {useParams} from "react-router-dom";
import {APIProvider, Map, Pin, AdvancedMarker} from "@vis.gl/react-google-maps";
import {handleResponse} from "../Scripts/handle-response";
import Loading from "../Loading/Loading";

import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import Button from "../Comps/Button";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CrawlView = () => {
    const [user] = useState(authenticationService.currentUserValue)
    const { id } = useParams()
    const [pubs, setPubs] = useState([])
    const [crawl, setCrawl] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [currentPub, setCurrentPub] = useState({})

    const getPubs = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':id, 'user':user.id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/findUserCrawlPubs`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setPubs(result)
                    getCrawl()
                },
                (error) => {console.log(error)}
            )
    }

    const getCrawl = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':id})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/getCrawl`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    setCrawl(result[0])
                },
                (error) => {console.log(error)}
            )
    }

    const updateCrawl = (updatePub) => {
        let temp = crawl
        if (updatePub > 0 && updatePub <= pubs.length) temp.current_pub = updatePub

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'crawl':temp})
        };

        fetch(`${process.env.REACT_APP_API_ROUTES}/pub/updateCrawl`, requestOptions)
            .then(handleResponse)
            .then((result) => {
                    console.log(result)
                    getCrawl()
                },
                (error) => {console.log(error)}
            )
    }

    useEffect(() => {
        getPubs()
    }, [])

    useEffect(() => {
        if (!!pubs && !!crawl && pubs.length > 0 && crawl.id > 0) {
            if (Object.keys(currentPub).length === 0) {
                setCurrentPub(pubs.find(x => x.order === crawl.current_pub))
            } else {
                setCurrentPub(pubs.find(x => x.order === crawl.current_pub))
                setLoaded(true)
            }
        }
    }, [currentPub, pubs, crawl])

    let extra = <></>
    if (user.admin) {
        extra = (
            <div className="text-right">
                <Button title={"Next Pub"} onClick={() => updateCrawl(crawl.current_pub+1)} />
                <Button title={"Prev Pub"} onClick={() => updateCrawl(crawl.current_pub-1)} />
            </div>
        )
    }

    if (loaded) {
        return (
            <>
                <Title title={crawl.title} desc={crawl.desc} extra={extra} />

                <div className="self-center max-w-2xl lg:px-4 sm:px-6 sm:py-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:border lg:border-gray-200 lg:bg-white rounded-md">
                    {/* Pub */}
                    <div className="lg:max-w-lg">
                        <div className="mt-4">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{currentPub.name}</h1>
                        </div>

                        <section aria-labelledby="information-heading" className="mt-4">
                            <div className="flex items-center">
                                <p className="text-lg text-gray-900 sm:text-xl">Start Time: {new Date(currentPub.start_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                            </div>

                            <div className="mt-2 space-y-6">
                                <p className="text-lg text-gray-900 sm:text-xl">End Time: {new Date(currentPub.end_time).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit'})}</p>
                            </div>

                            <div className="mt-2 flex items-center">
                                {currentPub.pubbed ? (
                                    <>
                                        <span className="inline-flex flex-shrink-0 items-center rounded-lg bg-green-400/70 px-1.5 py-0.5 text-lg font-medium text-green-950 ring-1 ring-inset ring-green-800/20">
                                            Inebriated
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span className="inline-flex flex-shrink-0 items-center rounded-lg bg-red-400/70 px-1.5 py-0.5 text-lg font-medium text-red-950 ring-1 ring-inset ring-red-800/20">
                                            Sober
                                        </span>
                                    </>
                                )}
                            </div>
                        </section>
                    </div>

                    {/* Map */}
                    <div className="mt-8 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
                        <div className="aspect-square w-full rounded-lg object-cover">
                            <APIProvider apiKey={process.env.REACT_APP_GOOGLE} onLoad={() => console.log('Maps API has loaded.')}>
                                <Map
                                    defaultZoom={15}
                                    defaultCenter={{lat: currentPub.lat, lng: currentPub.long}}
                                    mapId={"226fc07b2976381b"}
                                >
                                    {pubs.map((item) => (
                                        <AdvancedMarker position={{ lat: item.lat, lng: item.long}} >
                                            {currentPub.order < item.order && (
                                                <Pin
                                                    background={'#0f679d'}
                                                    borderColor={'#00529d'}
                                                    glyphColor={'#6092b5'}
                                                />
                                            )}
                                            {currentPub.order > item.order && (
                                                <Pin
                                                    background={'#0f9d58'}
                                                    borderColor={'#006425'}
                                                    glyphColor={'#60d98f'}
                                                />
                                            )}
                                            {currentPub.order === item.order && (
                                                <Pin
                                                    background={'#9d1b0f'}
                                                    borderColor={'#9d0000'}
                                                    glyphColor={'#9d6060'}
                                                />
                                            )}
                                        </AdvancedMarker>
                                    ))}
                                </Map>
                            </APIProvider>
                        </div>
                    </div>

                    {/* Product form */}
                    <div className="mt-8 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
                        <section aria-labelledby="options-heading">

                            <div className="flex items-center">
                                <p className="text-lg text-gray-900 sm:text-xl">{currentPub.desc}</p>
                            </div>

                            <div className="mt-2 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
                                <img alt={currentPub.name} src={currentPub.img} className="aspect-square w-full rounded-lg object-cover" />
                            </div>

                        </section>
                    </div>
                </div>

            </>
        )
    } else {
        return (<Loading />)
    }
}

export default CrawlView;
