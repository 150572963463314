import React from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom"

import Home from "./Home/Home";
import PlayerHome from "./Home/PlayerHome";
import Create from "./Create/create";
import Profile from "./Users/Profile"
import Quiz from "./Quiz/Quiz";
import EditUser from "./Users/Edit";
import Admin from "./Admin/Admin";
import Game from "./Game/Game";
import Host from "./Host/Host";
import Play from "./Play/Play"
import Cards from "./Cards/cards";
import View from "./Users/View";
import Store from "./Store/Store";
import F0F from "./Errors/404";
import WIP from "./Errors/wip";
import Roadmap from "./Roadmap/Roadmap";
import PatchNotes from "./Patchnotes/PatchNotes";
import Bingo from "./Bingo/Bingo";
import Pub from "./Pub/Pub";

import Login from "./Login/Login";
import NewUser from "./Login/newUser"
import {SnackbarProvider} from "notistack";
import {authenticationService} from "./Scripts/authentication.service";
import Header from "./Header/Header";
import CrawlEdit from "./Pub/CrawlEdit";
import CrawlView from "./Pub/CrawlView";

function PrivateOutlet() {
    const auth = authenticationService.currentUserValue;
    return auth ? (
        <>
            <Header />
            <main className="lg:py-10 sm:py-2 lg:pl-72 h-[calc(svh - 64px)] md:h-svh">
                <div className="px-4 sm:px-6 md:px-8 h-full flex flex-col">
                    <Outlet />
                </div>
            </main>
        </>
    ) : <Navigate to="/home" />;
}

export default function App() {
    return (
        <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
                <Routes >
                    <Route path="/home" element={<Home />} />
                    <Route exact path="/" element={<PrivateOutlet />}>
                        <Route path="" element={<PlayerHome />} />
                        <Route path="roadmap" element={<Roadmap />} />
                        <Route path="patchnotes" element={<PatchNotes />} />

                        <Route path="join" element={<Game />} />
                        <Route path="quizzes" element={<Quiz />} />
                        <Route path="host" element={<Host />} />
                        <Route path="play" element={<Play />} />
                        <Route path="cards" element={<WIP />} />
                        <Route path="cards" element={<Cards />} />

                        <Route path="profile/edit" element={<EditUser />} />
                        <Route path="profile/:id" element={<Profile />} />
                        <Route path="profile" element={<View />} />

                        <Route path="pub" element={<Pub />} />
                        <Route path="pub/:id/edit" element={<CrawlEdit />} />
                        <Route path="pub/:id/view" element={<CrawlView />} />

                        <Route path="store" element={<WIP />} />
                        <Route path="store" element={<Store />} />
                        <Route path="inventory" element={<WIP />} />

                        <Route path="creator" element={<Create />} />
                        <Route path="admin" element={<Admin />} />
                        <Route path="bingo" element={<Bingo />} />

                        <Route path="*" element={<F0F />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/newUser" element={<NewUser />} />
                </Routes>
            </BrowserRouter>
        </SnackbarProvider>
    );
}
