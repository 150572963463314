import React from "react";

const Title = ({title, desc, extra = <></>}) => {
    return (
        <div className="lg:pb-6 grid grid-cols-2 w-full">
            <div className="col-start-1 row-start-1 w-full">
                <h3 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">{title}</h3>
                <p className="mt-4 text-lg leading-8 text-gray-600">
                    {desc}
                </p>
            </div>
            <div className="col-start-2 row-start-1 w-full">
                {extra}
            </div>
        </div>
    );
};

export default Title;
